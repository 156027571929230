import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Descriptions, Card, Typography, Col
} from 'antd';

import { ORDER_STATUS } from 'utils/constants';
import IconOut from 'images/icon-out.svg';
import CopyIcon from 'images/icon-copy.svg';

import TrackingIdForm from 'components/Form/TrackingIdForm';

const TrackingDetail = ({ orderDetail, onUpdate }) => {
  const orderStatus = _get(orderDetail, 'status');
  const shippingCompanyOfOrder = _get(orderDetail, 'trackingInfo', []);
  const isAirLogiOrder = _get(orderDetail, 'warehouse_airLogiIntegrated');

  if (orderStatus === ORDER_STATUS.DELIVERING && !isAirLogiOrder) {
    return (
      <Col span={24}>
        <Card
          className="highlight-card"
          bordered={false}
          title="荷物お問い合わせ"
          headStyle={{ padding: '0 16px', margin: 0 }}
          bodyStyle={{ padding: 16 }}
        >
          <TrackingIdForm
            showDeliveryServiceSection
            initialValues={shippingCompanyOfOrder}
            onSubmit={onUpdate}
            inHouseDelivery={_get(orderDetail, 'inHouseDelivering')}
          />
        </Card>
      </Col>
    );
  }

  if (shippingCompanyOfOrder.length === 0) {
    return null;
  }

  return (
    <Col span={24}>
      <Card
        className="highlight-card"
        bordered={false}
        title="荷物お問い合わせ"
        headStyle={{ padding: '0 16px', margin: 0 }}
        bodyStyle={{ padding: 16 }}
      >
        <Descriptions
          className="tracking-id-description"
          layout="horizontal"
          column={1}
          bordered
        >
          {_map(shippingCompanyOfOrder, (shippingCompany, scIndex) => {
            let trackingIds = _get(shippingCompany, 'trackingIds', []);
            if (_isEmpty(trackingIds)) {
              trackingIds = [''];
            }
            return _map(trackingIds, (trackingId, tIndex) => (
              <Descriptions.Item
                className={(() => {
                  let classStr = '';
                  if (
                    tIndex === trackingIds.length - 1
                    && scIndex !== shippingCompanyOfOrder.length - 1
                  ) {
                    classStr += ' description-item-border-bottom';
                  }
                  if (tIndex === 0 && scIndex !== 0) {
                    classStr += ' description-item-border-top';
                  }
                  return classStr;
                })()}
                label={
                  tIndex === 0
                  && (_get(shippingCompany, 'website') ? (
                    <a
                      href={_get(shippingCompany, 'website')}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <span style={{ color: '#000000a6' }}>
                        {_get(shippingCompany, 'nameKana', '--')}
                      </span>
                      <img
                        style={{ marginLeft: 4 }}
                        alt="icon-out"
                        src={IconOut}
                        height={16}
                      />
                    </a>
                  ) : (
                    <span style={{ color: '#000000a6' }}>
                      {_get(shippingCompany, 'nameKana', '--')}
                    </span>
                  ))
                }
              >
                <Typography.Paragraph
                  style={{ marginBottom: 0 }}
                  copyable={
                    trackingId !== '' && {
                      tooltips: ['コピー', 'コピーしました'],
                      icon: <img src={CopyIcon} alt="copy" />,
                    }
                  }
                >
                  {trackingId}
                </Typography.Paragraph>
              </Descriptions.Item>
            ));
          })}
        </Descriptions>
      </Card>
    </Col>
  );
};

TrackingDetail.propTypes = {
  onUpdate: PropTypes.func,
  orderDetail: PropTypes.object,
};

export default TrackingDetail;
