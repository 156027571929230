import _get from 'lodash/get';
import _map from 'lodash/map';
import React, { useEffect, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
import {
  List, Row, Col, Tag, Card, Typography, Space, Alert
} from 'antd';
import { useParams } from 'react-router-dom';

import Helpers from 'utils/helpers';

import CouponIcon from 'images/coupon.svg';

import withRefresh from 'components/HOCs/withRefresh';
import Table from 'components/Table';
import PageHeader from 'components/PageHeader';
import { getOrderDetailRequest } from 'providers/OrderProvider/actions';

import DeliveryInfo from './DeliveryInfo';
import OrderMessage from './OrderMessage';
import Columns from './Columns';
import SupplierInfo from './SupplierInfo';
import NavigationButton from './NavigationButton';
import TrackingDetail from './TrackingDetail';

import './style.scss';

const { Text } = Typography;

const OrderDetail = () => {
  const orderDetail = useSelector(
    (state) => state.orderProvider.orderDetails,
    shallowEqual
  );

  const updateItems = _get(orderDetail, 'updateItems', []);

  const orderList = useSelector(
    (state) => state.orderProvider.orderList,
    shallowEqual
  );
  const loading = useSelector(
    (state) => state.orderProvider.loading,
    shallowEqual
  );
  const dispatch = useDispatch();

  const { orderId } = useParams();

  const orderStatus = _get(orderDetail, 'status');

  const breadcrumbItems = [
    {
      path: '/orders',
      breadcrumbName: '注文一覧',
    },
    {
      breadcrumbName: `#${orderId}`,
    },
  ];

  const getOrderDetail = useCallback(async () => {
    dispatch(getOrderDetailRequest({ id: orderId }));
  }, [orderId]);

  useEffect(() => {
    getOrderDetail();
  }, [getOrderDetail]);

  const renderOrderList = () => {
    const data = [
      ..._map(orderDetail?.items, (i) => ({
        ...i,
        itemType: 'ORDER_ITEM',
      })),
      ..._map(updateItems, (i) => ({
        ...i,
        itemType: 'UPDATE_ITEM',
      })),
    ];

    if (orderDetail?.discountPoint) {
      data.push({
        itemType: 'DISCOUNT_POINT_ITEM',
        name: `${Helpers.numberWithCommas(
          orderDetail?.discountPoint
        )}ポイント利用`,
        value: -orderDetail?.discountPoint,
      });
    }

    const columns = [
      Columns.menuName,
      Columns.price,
      Columns.quantity,
      Columns.taxRate,
      Columns.totalPriceAfterTax,
    ];

    return (
      <Table
        rowKey="objectId"
        columns={columns}
        data={data}
        total={data.length}
        loading={loading}
        pagination={null}
        scroll={{ x: 540 }}
      />
    );
  };

  const OrderHeader = () => (
    <Space direction="vertical" size={0} className="order-header" align="start">
      <Typography.Text>Order #{orderId}</Typography.Text>
    </Space>
  );

  return (
    <div id="order-detail-container">
      <PageHeader
        title={<OrderHeader />}
        breadcrumbRoutes={breadcrumbItems}
        onBack={() => dispatch(goBack())}
        extra={NavigationButton(orderId, dispatch, orderList)}
      />
      <Card
        className="bg-transparent"
        bordered={false}
        bodyStyle={{ paddingTop: 0 }}
      >
        <Row gutter={[16, 16]}>
          <Col xl={{ span: 15 }} md={{ span: 24 }} xs={{ span: 24 }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card
                  className="highlight-card padding-card"
                  bordered={false}
                  title="注文商品"
                  headStyle={{
                    fontSize: '20px',
                    color: '#000',
                    padding: '0 16px',
                    borderBottom: '2px solid #e8e8e8',
                  }}
                >
                  {renderOrderList()}
                  <List
                    id="order-summary-list"
                    style={{ margin: '0 16px' }}
                    itemLayout="horizontal"
                    dataSource={[
                      {
                        title: _get(orderDetail, 'couponCode') && (
                          <Alert
                            style={{
                              width: 'max-content',
                              maxWidth: '100%',
                              marginRight: '12px',
                            }}
                            icon={(
                              <img
                                alt="note-icon"
                                src={CouponIcon}
                                style={{ fill: 'green' }}
                              />
                            )}
                            showIcon
                            message={(
                              <div style={{ whiteSpace: 'pre-line' }}>
                                {_get(orderDetail, 'couponCode')}
                              </div>
                            )}
                            type="warning"
                          />
                        ),
                        content: (
                          <div className="total-value">
                            <Row justify="space-between">
                              <Col>小計（税込）</Col>
                              <Col>
                                {Helpers.priceFormat(
                                  (orderDetail?.total || 0)
                                    - (orderDetail?.shippingFee || 0)
                                )}
                              </Col>
                            </Row>
                            <Row justify="space-between">
                              <Col>
                                送料
                                {orderDetail?.shippingFee ? '（税込）' : ''}
                              </Col>
                              <Col>
                                {orderDetail?.shippingFee ? (
                                  Helpers.priceFormat(orderDetail?.shippingFee)
                                ) : (
                                  <Tag
                                    style={{ float: 'right', margin: 0 }}
                                    color="blue"
                                  >
                                    無料
                                  </Tag>
                                )}
                              </Col>
                            </Row>
                            <Row gutter={64} style={{ marginTop: 16 }}>
                              <Col>
                                <Text strong>総計（税込）</Text>
                              </Col>
                              <Col>
                                <Text strong>
                                  {Helpers.priceFormat(orderDetail?.total || 0)}
                                </Text>
                              </Col>
                            </Row>
                          </div>
                        ),
                      },
                    ]}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          className="text-bold"
                          title={item.title}
                        />
                        <div className="text-bold">{item.content}</div>
                      </List.Item>
                    )}
                  />
                </Card>
              </Col>
              <TrackingDetail orderDetail={orderDetail} />
            </Row>
          </Col>
          <Col xl={{ span: 9 }} md={{ span: 24 }} xs={{ span: 24 }}>
            <Space direction="vertical" size={16} className="full-w">
              <DeliveryInfo orderDetail={orderDetail} />
              <SupplierInfo orderDetail={orderDetail} />
              <OrderMessage orderId={orderId} orderStatus={orderStatus} />
            </Space>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default withRefresh('orderDetail')(OrderDetail);
