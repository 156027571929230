import _get from 'lodash/get';
import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  ShopOutlined,
  SolutionOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  Menu, Layout, Typography, Badge
} from 'antd';

import { USER_ROLE } from 'utils/constants';

import meta from 'meta.json';
import ForOperator from 'images/logo-operator.svg';
import ForSupplier from 'images/logo-supplier.svg';
import ForBrandOwner from 'images/logo-brand-owner.svg';
import ForRestaurantOwner from 'images/logo-restaurant-owner.svg';
import BrandIcon from 'images/icon-brand.svg';
import SupplierIcon from 'images/icon-supplier.svg';
import NotificationIcon from 'images/icon-announcement.svg';
import GuestIcon from 'images/icon-guest.svg';
import ManufacturerIcon from 'images/icon-manufacturer.svg';
import PointIcon from 'images/icon-point.svg';
import MenuIcon from 'images/icon-menu.svg';
import RestaurantOwnerIcon from 'images/icon-restaurant-owner.svg';
import CollectionIcon from 'images/icon-collection.svg';
import BannerIcon from 'images/icon-banner.svg';

import './style.less';

const { Sider } = Layout;

const LogoByRole = {
  [USER_ROLE.OPERATOR]: ForOperator,
  [USER_ROLE.SUPPLIER]: ForSupplier,
  [USER_ROLE.BRAND_OWNER]: ForBrandOwner,
  [USER_ROLE.RESTAURANT_OWNER]: ForRestaurantOwner,
};
const supplierType = 'SHIKOMEL';

const MenuComponent = () => {
  const [collapsed, setCollapsed] = useState(false);

  const dispatch = useDispatch();
  const onMenuItemClick = ({ key }) => {
    dispatch(push(`/${key}`));
  };

  const { pathname } = useLocation();
  const selectedKeys = [pathname.split('/')?.[1]];

  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  const isShikomelSupplier = _get(currentUser, 'supplier.type') === supplierType;

  return (
    <Sider
      id="sider-container"
      collapsible
      collapsed={collapsed}
      trigger={null}
      theme="light"
    >
      <div className="menu-container">
        <div className="collapse-btn-container">
          {collapsed ? (
            <MenuUnfoldOutlined
              className="collapse-icon"
              onClick={() => {
                setCollapsed(false);
              }}
            />
          ) : (
            <MenuFoldOutlined
              className="collapse-icon"
              onClick={() => {
                setCollapsed(true);
              }}
            />
          )}
        </div>
        <div className="logo-container flex items-center">
          {!collapsed && (
            <img src={LogoByRole[currentUser.role]} alt="for-role" />
          )}
        </div>
        <Menu
          mode="inline"
          theme="light"
          selectedKeys={selectedKeys}
          defaultOpenKeys={['product-container', 'app-management']}
          onClick={onMenuItemClick}
        >
          {[
            USER_ROLE.SUPPLIER,
            USER_ROLE.OPERATOR,
            USER_ROLE.BRAND_OWNER,
            USER_ROLE.RESTAURANT_OWNER,
          ].includes(currentUser?.role) && (
            <Menu.Item key="orders" icon={<SolutionOutlined />}>
              注文一覧
            </Menu.Item>
          )}
          {currentUser.role === USER_ROLE.BRAND_OWNER && (
            <Menu.Item
              key="brands"
              icon={<img src={BrandIcon} alt="brand-icon" />}
            >
              ブランド
            </Menu.Item>
          )}
          {currentUser.role === USER_ROLE.SUPPLIER && !isShikomelSupplier && (
            <>
              <Menu.Item
                key="products"
                icon={<img src={MenuIcon} alt="product-icon" />}
              >
                商品
              </Menu.Item>
              <Menu.Item
                key="collections"
                icon={<img src={CollectionIcon} alt="collection-icon" />}
              >
                セット商品
              </Menu.Item>
            </>
          )}
          {isShikomelSupplier && (
            <>
              <Menu.SubMenu
                icon={<img src={MenuIcon} alt="product-icon" />}
                title="商品"
                key="product-container"
              >
                <Menu.Item key="products">全ての商品</Menu.Item>
                <Menu.Item key="tags">商品のタグ</Menu.Item>
              </Menu.SubMenu>
              <Menu.Item
                key="collections"
                icon={<img src={CollectionIcon} alt="collection-icon" />}
              >
                セット商品
              </Menu.Item>
            </>
          )}
          {[
            USER_ROLE.SUPPLIER,
            USER_ROLE.BRAND_OWNER,
            USER_ROLE.RESTAURANT_OWNER,
          ].includes(currentUser?.role) && (
            <Menu.Item
              key="restaurants"
              icon={<ShopOutlined />}
              className="restaurants-item"
            >
              <p>レストラン</p>
              {_get(currentUser, 'supplier.supplierRestaurantRequestCount', 0)
                > 0 && (
                <p className="invitations-restaurant">
                  新規申請：
                  <Badge status="processing" />
                  <Badge
                    className="invitations-count"
                    count={_get(
                      currentUser,
                      'supplier.supplierRestaurantRequestCount'
                    )}
                  />
                  件
                </p>
              )}
            </Menu.Item>
          )}
          {[USER_ROLE.OPERATOR].includes(currentUser?.role) && (
            <Menu.SubMenu
              icon={<ShopOutlined />}
              title="レストラン"
              key="restaurant-container"
            >
              <Menu.Item key="restaurants">全てのレストラン</Menu.Item>
              <Menu.Item key="restaurant-group">レストラングループ</Menu.Item>
            </Menu.SubMenu>
          )}
          {[USER_ROLE.BRAND_OWNER, USER_ROLE.OPERATOR].includes(
            currentUser?.role
          ) && (
            <Menu.Item
              key="suppliers"
              icon={<img src={SupplierIcon} alt="supplier-icon" />}
            >
              販売者
            </Menu.Item>
          )}
          {[USER_ROLE.SUPPLIER, USER_ROLE.OPERATOR].includes(
            currentUser?.role
          ) && (
            <Menu.Item
              key="brands"
              icon={<img src={BrandIcon} alt="brand-icon" />}
            >
              ブランド
            </Menu.Item>
          )}
          {currentUser.role === USER_ROLE.OPERATOR && (
            <>
              <Menu.Item
                key="restaurant-owner"
                icon={<img src={RestaurantOwnerIcon} alt="product-icon" />}
              >
                レストランオーナー
              </Menu.Item>
            </>
          )}
          {[USER_ROLE.BRAND_OWNER].includes(currentUser?.role) && (
            <Menu.Item
              key="products"
              icon={<img src={MenuIcon} alt="product-icon" />}
            >
              商品
            </Menu.Item>
          )}
          {[USER_ROLE.OPERATOR].includes(currentUser?.role) && (
            <Menu.SubMenu
              icon={<img src={MenuIcon} alt="product-icon" />}
              title="商品"
              key="product-container"
            >
              <Menu.Item key="products">全ての商品</Menu.Item>
              <Menu.Item key="rankings">ランキング</Menu.Item>
            </Menu.SubMenu>
          )}
          {[USER_ROLE.OPERATOR].includes(currentUser?.role) && (
            <Menu.Item
              key="point-history"
              icon={<img src={PointIcon} alt="point-history" />}
            >
              ポイント付与・利用履歴
            </Menu.Item>
          )}
          {[USER_ROLE.SUPPLIER, USER_ROLE.OPERATOR].includes(
            currentUser?.role
          ) && (
            <Menu.Item
              key="manufacturers"
              icon={<img src={ManufacturerIcon} alt="manufacturer-icon" />}
            >
              製造会社
            </Menu.Item>
          )}
          {currentUser.role === USER_ROLE.OPERATOR && (
            <>
              <Menu.Item
                key="guest-information"
                icon={<img src={GuestIcon} alt="guest-icon" />}
              >
                ゲスト基本情報
              </Menu.Item>
              <Menu.Item
                key="announcements"
                icon={<img src={NotificationIcon} alt="notification-icon" />}
              >
                プッシュ通知
              </Menu.Item>
              <Menu.SubMenu
                icon={<img src={BannerIcon} alt="banner-icon" />}
                title="バナー"
                key="app-management"
              >
                <Menu.Item key="banners">バナー</Menu.Item>
                <Menu.Item key="in-app-messages">
                  TOPお知らせメッセージ
                </Menu.Item>
              </Menu.SubMenu>
            </>
          )}
          {[
            USER_ROLE.SUPPLIER,
            USER_ROLE.BRAND_OWNER,
            USER_ROLE.RESTAURANT_OWNER,
          ].includes(currentUser?.role) && (
            <Menu.Item key="settings" icon={<SettingOutlined />}>
              各種設定
            </Menu.Item>
          )}
        </Menu>
        {/* Put a clone build-number component here to takes space for rendering the real one */}
        <Typography.Text
          className="build-number-container"
          style={{ visibility: 'hidden', position: 'static' }}
        >
          {meta.version} ({meta.date})
        </Typography.Text>
      </div>
      <Typography.Text className="build-number-container">
        {meta.version} ({meta.date})
      </Typography.Text>
    </Sider>
  );
};

export default MenuComponent;
