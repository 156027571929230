import _get from 'lodash/get';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _toNumber from 'lodash/toNumber';
import _isNaN from 'lodash/isNaN';
import _findIndex from 'lodash/findIndex';
import _reduce from 'lodash/reduce';
import _last from 'lodash/last';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  reset, isDirty, submit, getFormValues, isValid
} from 'redux-form';
import { goBack } from 'connected-react-router';
import {
  CloseOutlined,
  CheckCircleFilled,
  ReloadOutlined,
  CloseCircleFilled,
} from '@ant-design/icons';
import {
  Modal,
  List,
  Row,
  Col,
  Tag,
  Card,
  Button,
  Typography,
  Space,
  Affix,
  InputNumber,
  Tooltip,
  Alert,
} from 'antd';
import { useParams } from 'react-router-dom';

import Helpers from 'utils/helpers';
import { ORDER_STATUS, DATE_TIME_FORMAT } from 'utils/constants';
import toastMessage from 'utils/toastMessage';

import CouponIcon from 'images/coupon.svg';
import WarningImage from 'images/icon-warning.svg';

import withRefresh from 'components/HOCs/withRefresh';
import Table from 'components/Table';
import PageHeader from 'components/PageHeader';
import {
  getOrderDetailRequest,
  updateOrderItemsRequest,
  updateOrderStatusRequest,
  updateTrackingInfoRequest,
  reRegisterOrderToAirLogiRequest,
} from 'providers/OrderProvider/actions';
import OrderItemUpdate from './OrderItemUpdate';
import TrackingIdModal from '../Components/TrackingIdModal';
import DeliveryInfo from './DeliveryInfo';
import OrderMessage from './OrderMessage';
import Columns from './Columns';
import NavigationButton from './NavigationButton';

import './style.scss';
import TrackingDetail from './TrackingDetail';

const { confirm } = Modal;
const { Text } = Typography;

const supplierType = 'SHIKOMEL';

const showConfirm = (message, actionFn, confirmText) => {
  confirm({
    title: message,
    okText: _get(confirmText, 'yes', 'はい'),
    okType: 'primary',
    cancelText: _get(confirmText, 'no', 'いいえ'),
    onOk: () => {
      actionFn();
    },
  });
};

const OrderDetail = () => {
  const [trackingIdModal, setTrackingIdModal] = useState(false);
  const [updateOrderLoading, setUpdateOrderLoading] = useState(false);
  const [retryOrderLoading, setRetryOrderLoading] = useState(false);
  const [menuItemList, setMenuItemList] = useState([]);
  const [isDirtyMenuItems, setIsDirtyMenuItem] = useState(false);
  const [isCheckAllItemCheckMark, setIsCheckAllItemCheckMark] = useState(false);

  useEffect(() => {
    setIsDirtyMenuItem(
      _findIndex(menuItemList, (i) => i.isDirtyPrice || i.isDirtyQuantity) > -1
    );
    setIsCheckAllItemCheckMark(
      _findIndex(
        menuItemList,
        (i) => (i.isIndefinitePrice || i.isMarketPrice)
          && !i.isCheckedBeforeDelivering
      ) < 0
    );
  }, [menuItemList]);

  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const orderDetail = useSelector(
    (state) => state.orderProvider.orderDetails,
    shallowEqual
  );
  const orderList = useSelector(
    (state) => state.orderProvider.orderList,
    shallowEqual
  );
  const loading = useSelector(
    (state) => state.orderProvider.loading,
    shallowEqual
  );
  const dispatch = useDispatch();

  const isDirtyUpdateForm = useSelector((state) => isDirty('orderItemUpdate')(state)
  );
  const isDirtyTrackingIdForm = useSelector((state) => isDirty('trackingIdForm')(state)
  );

  const orderItemFormValues = useSelector((state) => getFormValues('orderItemUpdate')(state)
  );

  const isValidUpdateForm = useSelector((state) => isValid('orderItemUpdate')(state)
  );
  const isValidTrackingIdForm = useSelector((state) => isValid('trackingIdForm')(state)
  );
  const isShikomelSupplier = _get(currentUser, 'supplier.type') === supplierType;

  const { orderId } = useParams();

  const orderStatus = _get(orderDetail, 'status');
  const updateItems = _get(orderDetail, 'updateItems', []);

  useEffect(() => {
    setMenuItemList(
      _map(orderDetail?.items, (i) => ({
        ...i,
        originalItem: i.originalItem || i,
        previousItem: i,
      }))
    );
  }, [orderDetail?.items]);

  const breadcrumbItems = [
    {
      path: '/orders',
      breadcrumbName: '注文一覧',
    },
    {
      breadcrumbName: `#${orderId}`,
    },
  ];

  const getOrderDetail = useCallback(async () => {
    dispatch(getOrderDetailRequest({ id: orderId }));
  }, [orderId]);

  useEffect(() => {
    getOrderDetail();
  }, [getOrderDetail]);

  const handleCancelOrder = () => {
    showConfirm(
      '注文をキャンセルしますか？',
      () => dispatch(
        updateOrderStatusRequest({
          orderId,
          status: ORDER_STATUS.CANCELLED,
        })
      ),
      { yes: 'キャンセルする', no: 'もどる' }
    );
  };

  const handleTrackingIdModalSubmit = async (value) => {
    showConfirm('配送しますか？', async () => {
      await dispatch(
        updateOrderStatusRequest({
          orderId,
          status: ORDER_STATUS.DELIVERING,
          ...value,
        })
      );
      setTrackingIdModal(false);
    });
  };

  const StatusUpdateButtons = () => {
    switch (orderStatus) {
      case ORDER_STATUS.SUBMITTED:
        return (
          <>
            <Button
              key="proceed"
              type="primary"
              onClick={() => showConfirm('続行してよろしいですか？', () => dispatch(
                updateOrderStatusRequest({
                  orderId,
                  status: ORDER_STATUS.PROCESSING,
                })
              )
              )}
            >
              続行
            </Button>
          </>
        );

      case ORDER_STATUS.PROCESSING:
        return (
          <>
            <Button
              key="delivery"
              type="primary"
              onClick={() => {
                if (
                  !isCheckAllItemCheckMark
                  && _findIndex(
                    menuItemList,
                    (i) => i.isIndefinitePrice || i.isMarketPrice
                  ) > -1
                ) {
                  toastMessage.error({
                    description:
                      '時価または不定貫の商品の単価と数量を確認し、チェックをつけてからステータスを更新してください。',
                  });
                } else {
                  setTrackingIdModal(true);
                }
              }}
            >
              配送
            </Button>
          </>
        );

      case ORDER_STATUS.DELIVERING:
        return (
          <>
            <Button
              key="complete"
              type="primary"
              onClick={() => showConfirm('配送を完了しますか？', () => dispatch(
                updateOrderStatusRequest({
                  orderId,
                  status: ORDER_STATUS.DELIVERED,
                })
              )
              )}
            >
              配送を完了する
            </Button>
          </>
        );

      default:
        return null;
    }
  };

  const updateMenuItem = (menuId, price, quantity) => {
    const incomingList = [...menuItemList];
    const index = _findIndex(incomingList, (i) => i.objectId === menuId);

    // Recalculate Price-after-tax of Menu-item
    price = price || incomingList[index]?.price;
    quantity = quantity || incomingList[index]?.quantity;
    const { taxRate } = incomingList[index];
    const totalPriceAfterTax = Math.floor(price * quantity * (1 + taxRate));

    // Compare originalItem (before edit) and current value to check if Menu-item is edited or not
    const isEditPrice = incomingList[index]?.originalItem?.price !== price;
    const isEditQuantity = incomingList[index]?.originalItem?.quantity !== quantity;

    // Compare previous (before edit) and current value to check if Menu-item is dirty or not
    const isDirtyPrice = incomingList[index]?.previousItem?.price !== price;
    const isDirtyQuantity = incomingList[index]?.previousItem?.quantity !== quantity;

    // Update state
    incomingList[index] = {
      ...incomingList[index],
      price,
      quantity,
      totalPriceAfterTax,
      isEditPrice,
      isEditQuantity,
      isDirtyPrice,
      isDirtyQuantity,
    };
    setMenuItemList(incomingList);
  };

  const handleToggleItemCheckMark = (menuId) => {
    const incomingList = [...menuItemList];
    const index = _findIndex(
      incomingList,
      (i) => (i.isMarketPrice || i.isIndefinitePrice) && i.objectId === menuId
    );
    incomingList[index] = {
      ...incomingList[index],
      isCheckedBeforeDelivering: !incomingList[index].isCheckedBeforeDelivering,
    };
    setMenuItemList(incomingList);
  };

  const handleToggleAllItemCheckMark = () => {
    const incomingList = [...menuItemList];
    _forEach(incomingList, (i) => {
      if (i.isMarketPrice || i.isIndefinitePrice) {
        i.isCheckedBeforeDelivering = !isCheckAllItemCheckMark;
      }
    });
    setMenuItemList(incomingList);
  };

  const onPressEnter = (event) => {
    event.preventDefault();
    event.target.blur();
  };

  const renderOrderList = () => {
    const data = [
      ..._map(menuItemList, (i) => ({
        ...i,
        itemType: 'ORDER_ITEM',
      })),
    ];
    if (
      [ORDER_STATUS.FIXED, ORDER_STATUS.PAYMENT_FAILED].includes(orderStatus)
    ) {
      data.push(
        ..._map(updateItems, (i) => ({
          ...i,
          itemType: 'UPDATE_ITEM',
        }))
      );
    }

    const columns = [
      Columns.menuName,
      {
        ...Columns.price,
        render: (record) => {
          if (
            !(record.isIndefinitePrice || record.isMarketPrice)
            || ![
              ORDER_STATUS.SUBMITTED,
              ORDER_STATUS.DELIVERING,
              ORDER_STATUS.DELIVERED,
              ORDER_STATUS.PROCESSING,
            ].includes(orderStatus)
          ) {
            return (
              <span style={record.isEditPrice ? { color: '#08979c' } : {}}>
                {record.itemType === 'ORDER_ITEM'
                  && `¥${Helpers.numberWithCommas(record.price)}`}
              </span>
            );
          }
          return (
            <InputNumber
              addonBefore="¥"
              style={{ width: '100%' }}
              className={record.isEditPrice && 'change-price'}
              precision={0}
              min={0}
              value={record.price}
              formatter={(value) => Helpers.numberWithCommas(value)}
              onChange={(value) => updateMenuItem(record.objectId, value, undefined)}
              max={99999999}
              onPressEnter={onPressEnter}
            />
          );
        },
      },
      {
        ...Columns.quantity,
        render: (record) => {
          if (
            !(record.isIndefinitePrice || record.isMarketPrice)
            || ![
              ORDER_STATUS.SUBMITTED,
              ORDER_STATUS.DELIVERING,
              ORDER_STATUS.DELIVERED,
              ORDER_STATUS.PROCESSING,
            ].includes(orderStatus)
          ) {
            return (
              <span style={record.isEditQuantity ? { color: '#08979c' } : {}}>
                {record.itemType === 'ORDER_ITEM' && record.quantity}
              </span>
            );
          }
          return (
            <InputNumber
              precision={2}
              style={{ width: '100%' }}
              className={record.isEditQuantity && 'change-quantity'}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              value={record.quantity}
              onChange={(value) => updateMenuItem(record.objectId, undefined, value)}
              max={99999999}
              min={0}
              onPressEnter={onPressEnter}
            />
          );
        },
      },
      Columns.taxRate,
      Columns.totalPriceAfterTax,
    ];

    if (
      orderStatus === ORDER_STATUS.PROCESSING
      && _findIndex(menuItemList, (i) => i.isIndefinitePrice || i.isMarketPrice)
        > -1
    ) {
      columns.push({
        fixed: 'right',
        render: (record, item) => (record.isIndefinitePrice || record.isMarketPrice) && (
          <Tooltip
            placement="topLeft"
            title="時価または不定貫の商品の単価と数量を確認しチェックしてください。"
          >
            <CheckCircleFilled
              onClick={() => {
                handleToggleItemCheckMark(record.objectId);
              }}
              style={{
                color: item.isCheckedBeforeDelivering
                  ? '#399E0E'
                  : 'rgba(0, 0, 0, 0.25)',
              }}
            />
          </Tooltip>
        ),
        width: '45px',
        align: 'right',
      });
    }

    return (
      <Table
        rowKey="objectId"
        columns={columns}
        data={data}
        total={data.length}
        loading={loading}
        pagination={null}
        scroll={{ x: 540 }}
      />
    );
  };

  const handleUpdateTrackingId = async (value) => {
    if (!isValidTrackingIdForm || !isValidUpdateForm) {
      return;
    }

    setUpdateOrderLoading(true);
    try {
      await dispatch(
        updateTrackingInfoRequest({
          orderId,
          ...value,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setUpdateOrderLoading(false);
  };

  const handleUpdateOrderItems = async (value) => {
    if (!isValidTrackingIdForm || !isValidUpdateForm) {
      return;
    }

    const updateMenuItems = _reduce(
      menuItemList,
      (pre, i) => {
        if (i.isDirtyPrice || i.isDirtyQuantity) {
          pre.push({
            menuId: i.objectId,
            price: i.price,
            quantity: i.quantity,
          });
        }
        return pre;
      },
      []
    );

    setUpdateOrderLoading(true);
    try {
      await dispatch(
        updateOrderItemsRequest({
          orderId,
          ...(isDirtyUpdateForm ? value : {}),
          updateMenuItems,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setUpdateOrderLoading(false);
  };

  const handleSubmitDirtyOrderDetail = async () => {
    /**
     * If there are any changes in orderDetail, handle update both:
     * - Menu items changes
     * - Tracking Id form
     * - Returned menu and new shipping fee
     */
    if (isDirtyTrackingIdForm) {
      dispatch(submit('trackingIdForm'));
    }
    if (isDirtyUpdateForm || isDirtyMenuItems) {
      dispatch(submit('orderItemUpdate'));
    }
  };

  const renderCheckMarkBeforeDelivering = () => {
    if (
      orderStatus !== ORDER_STATUS.PROCESSING
      || _findIndex(menuItemList, (i) => i.isIndefinitePrice || i.isMarketPrice)
        < 0
    ) {
      return null;
    }
    return (
      <Space>
        <img
          alt="warning-icon"
          src={WarningImage}
          style={{ width: '16px', height: '16px' }}
        />
        <Text type="warning">全ての単価と数量を確認しました</Text>
        <CheckCircleFilled
          onClick={handleToggleAllItemCheckMark}
          style={
            isCheckAllItemCheckMark
              ? {
                color: '#399E0E',
              }
              : {
                color: 'rgba(0, 0, 0, 0.25)',
              }
          }
        />
      </Space>
    );
  };

  let recalculatedPriceAfterTax = 0;
  let recalculatedShippingFee = _get(
    orderDetail,
    'oldShippingFee',
    _get(orderDetail, 'shippingFee', 0)
  );

  _forEach(menuItemList, (item) => {
    recalculatedPriceAfterTax += item.totalPriceAfterTax;
  });
  _forEach(_get(orderItemFormValues, 'updateItems') || updateItems, (item) => {
    let value = _get(item, 'value', 0);
    value = _toNumber(value);
    value = _isNaN(value) ? 0 : value;
    if (item.type === 'NEW_SHIPPING_FEE') {
      recalculatedShippingFee = value;
    }
    if (item.type === 'RETURNED_MENU') {
      recalculatedPriceAfterTax += value;
    }
  });

  const previousPriceAfterTax = (orderDetail?.subTotal || 0) + (orderDetail?.taxFee || 0);
  const previousDiscountPoint = orderDetail?.discountPoint || 0;
  let recalculatedDiscountPoint = orderDetail?.discountPoint || 0;

  const diffPriceAfterTax = recalculatedPriceAfterTax - previousPriceAfterTax;
  if (diffPriceAfterTax < 0) {
    recalculatedDiscountPoint = previousDiscountPoint + diffPriceAfterTax;
    if (recalculatedDiscountPoint < 0) {
      recalculatedDiscountPoint = 0;
    }
  }

  const recalculatedTotal = recalculatedPriceAfterTax
    + recalculatedShippingFee
    - recalculatedDiscountPoint;

  const renderPointItem = () => {
    if (recalculatedDiscountPoint <= 0) {
      return null;
    }

    const data = [
      {
        itemType: 'DISCOUNT_POINT_ITEM',
        name: `${Helpers.numberWithCommas(
          recalculatedDiscountPoint
        )}ポイント利用`,
        value: -recalculatedDiscountPoint,
      },
    ];

    const columns = [Columns.menuName, Columns.totalPriceAfterTax];

    return (
      <Table
        showHeader={false}
        rowKey="objectId"
        columns={columns}
        data={data}
        total={data.length}
        loading={loading}
        pagination={null}
        scroll={{ x: 540 }}
      />
    );
  };

  const handleRetryOrder = async () => {
    setRetryOrderLoading(true);
    try {
      await dispatch(
        reRegisterOrderToAirLogiRequest({
          orderId,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setRetryOrderLoading(false);
  };

  const isDeliveringStatus = orderStatus === ORDER_STATUS.DELIVERING;
  const airLogiInfo = orderDetail?.airLogi_info;
  const isAirLogiOrder = _get(orderDetail, 'warehouse_airLogiIntegrated');
  const airLogiSyncStatusLog = orderDetail?.airLogi_syncStatusLog;
  const isShowRetryButton = isAirLogiOrder
    && [
      'REQUEST_CREATE_FAILED',
      'CREATE_CALLBACK_RESULT_FAILED',
      'ORDER_NOT_FOUND',
    ].includes(airLogiInfo?.syncStatus);
  const lastUpdated = isShikomelSupplier
    && isAirLogiOrder
    && !isShowRetryButton && (
    <Typography.Text type="secondary">
      最終更新日時:{' '}
      {moment(_last(airLogiSyncStatusLog)?.date).format(DATE_TIME_FORMAT)}
    </Typography.Text>
  );
  const parentOrderId = _get(orderDetail, 'parentOrderId');
  const childOrderList = _get(orderDetail, 'listChildOrderId', []).filter(
    (id) => id !== orderId
  );

  const errorMessage = isShikomelSupplier && (airLogiInfo?.errorMessage || airLogiInfo?.syncStatus === 'ORDER_NOT_FOUND') && (
    <Alert
      className="full-w"
      style={{ marginBottom: '16px' }}
      message={
        airLogiInfo?.syncStatus === 'ORDER_NOT_FOUND'
          ? 'AiR Logi同期エラー'
          : 'エアロジに出荷指示を登録中にエラーが発生しました。'
      }
      description={
        airLogiInfo?.syncStatus === 'ORDER_NOT_FOUND' ? (
          'AiR Logiで注文が見つかりません。'
        ) : (
          <>
            <span>{airLogiInfo?.errorMessage}</span>
            <br />
            問題を解決して再実行してください。
          </>
        )
      }
      type="error"
      icon={<CloseCircleFilled style={{ fontSize: '16px', marginTop: 4 }} />}
      showIcon
    />
  );

  const RetryButton = () => {
    if (!isShikomelSupplier || !isShowRetryButton) {
      return null;
    }

    return (
      <Button
        icon={<ReloadOutlined />}
        onClick={handleRetryOrder}
        type="primary"
        loading={retryOrderLoading}
      >
        再登録
      </Button>
    );
  };

  const cancelButton = [
    ORDER_STATUS.SUBMITTED,
    ORDER_STATUS.PROCESSING,
  ].includes(orderStatus) && (
    <Button onClick={handleCancelOrder} type="danger" icon={<CloseOutlined />}>
      注文をキャンセル
    </Button>
  );

  const OrderHeader = () => (
    <Space direction="vertical" size={0} className="order-header" align="start">
      <Typography.Text>Order #{orderId}</Typography.Text>
      {parentOrderId && isShikomelSupplier && (
        <Space
          className="full-w order-id-list"
          align="start"
          wrap
          size={[4, 0]}
        >
          <Typography.Text className="order-id-label">
            親注文ID: {orderDetail?.parentOrderId} |
          </Typography.Text>
          <Typography.Text className="order-id-label">
            その他の子注文:
          </Typography.Text>
          <Space wrap className="child-order-list" size={[4, 0]}>
            {childOrderList.map((childOrderId) => (
              <Button
                className="order-id"
                type="link"
                key={childOrderId}
                href={`/orders/detail/${childOrderId}`}
                target="_blank"
              >
                #{childOrderId}
              </Button>
            ))}
          </Space>
        </Space>
      )}
    </Space>
  );

  return (
    <>
      {trackingIdModal && (
        <TrackingIdModal
          visible
          orderDetail={orderDetail}
          onSubmit={handleTrackingIdModalSubmit}
          onCancel={() => setTrackingIdModal(false)}
        />
      )}
      <div id="order-detail-container">
        <PageHeader
          title={<OrderHeader />}
          breadcrumbRoutes={breadcrumbItems}
          onBack={() => dispatch(goBack())}
          extra={(
            <>
              {lastUpdated}
              <RetryButton />
              {(!isAirLogiOrder || isDeliveringStatus) && (
                <StatusUpdateButtons />
              )}
              {NavigationButton(orderId, dispatch, orderList)}
            </>
          )}
        />
        <Card
          className="bg-transparent"
          bordered={false}
          bodyStyle={{ paddingTop: 0 }}
        >
          {errorMessage}
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 15 }} md={{ span: 24 }} xs={{ span: 24 }}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Card
                    className="highlight-card padding-card"
                    bordered={false}
                    title="注文商品"
                    headStyle={{
                      fontSize: '20px',
                      color: '#000',
                      padding: '0 16px',
                      borderBottom: '2px solid #e8e8e8',
                    }}
                    extra={renderCheckMarkBeforeDelivering()}
                  >
                    {renderOrderList()}
                    {[
                      ORDER_STATUS.SUBMITTED,
                      ORDER_STATUS.DELIVERING,
                      ORDER_STATUS.DELIVERED,
                      ORDER_STATUS.PROCESSING,
                    ].includes(orderStatus) && (
                      <OrderItemUpdate
                        orderDetail={orderDetail}
                        onSubmit={handleUpdateOrderItems}
                      />
                    )}
                    {renderPointItem()}
                    <List
                      id="order-summary-list"
                      style={{ margin: '0 16px' }}
                      itemLayout="horizontal"
                      dataSource={[
                        {
                          title: _get(orderDetail, 'couponCode') && (
                            <Alert
                              style={{
                                width: 'max-content',
                                maxWidth: '100%',
                                marginRight: '12px',
                              }}
                              icon={(
                                <img
                                  alt="note-icon"
                                  src={CouponIcon}
                                  style={{ fill: 'green' }}
                                />
                              )}
                              showIcon
                              message={(
                                <div style={{ whiteSpace: 'pre-line' }}>
                                  {_get(orderDetail, 'couponCode')}
                                </div>
                              )}
                              type="warning"
                            />
                          ),
                          content: (
                            <div className="total-value">
                              <Row justify="space-between">
                                <Col>小計（税込）</Col>
                                <Col>
                                  {Helpers.priceFormat(
                                    recalculatedTotal - recalculatedShippingFee
                                  )}
                                </Col>
                              </Row>
                              <Row justify="space-between">
                                <Col>
                                  送料
                                  {recalculatedShippingFee ? '（税込）' : ''}
                                </Col>
                                <Col>
                                  {recalculatedShippingFee ? (
                                    Helpers.priceFormat(recalculatedShippingFee)
                                  ) : (
                                    <Tag
                                      style={{ float: 'right', margin: 0 }}
                                      color="blue"
                                    >
                                      無料
                                    </Tag>
                                  )}
                                </Col>
                              </Row>
                              <Row gutter={64} style={{ marginTop: 16 }}>
                                <Col>
                                  <Text strong>総計（税込）</Text>
                                </Col>
                                <Col>
                                  <Text strong>
                                    {Helpers.priceFormat(recalculatedTotal)}
                                  </Text>
                                </Col>
                              </Row>
                            </div>
                          ),
                        },
                      ]}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            className="text-bold"
                            title={item.title}
                          />
                          <div className="text-bold">{item.content}</div>
                        </List.Item>
                      )}
                    />
                  </Card>
                </Col>
                <TrackingDetail
                  orderDetail={orderDetail}
                  onUpdate={handleUpdateTrackingId}
                />
                <Col xl={{ span: 24 }} md={{ span: 0 }} xs={{ span: 0 }}>
                  {cancelButton}
                </Col>
              </Row>
            </Col>
            <Col xl={{ span: 9 }} md={{ span: 24 }} xs={{ span: 24 }}>
              <Space direction="vertical" size={16} className="full-w">
                <DeliveryInfo orderDetail={orderDetail} />
                <OrderMessage orderId={orderId} orderStatus={orderStatus} />
              </Space>
            </Col>
            <Col xl={{ span: 0 }} md={{ span: 24 }} xs={{ span: 24 }}>
              {cancelButton}
            </Col>
          </Row>
        </Card>
        {(isDirtyUpdateForm || isDirtyTrackingIdForm || isDirtyMenuItems)
          && !trackingIdModal && (
          <Affix offsetBottom={0} className="full-w">
            <div className="full-w" style={{ background: '#293137' }}>
              <Space
                size={8}
                className="flex justify-between"
                style={{ padding: '16px 32px' }}
              >
                <Text style={{ color: '#fff' }}>保存されていない変更</Text>
                <Space size={8} className="full-w flex justify-end">
                  <Button
                    type="default"
                    onClick={() => {
                      if (isDirtyMenuItems) {
                        setMenuItemList(
                          _map(orderDetail?.items, (i) => ({
                            ...i,
                            itemType: 'ORDER_ITEM',
                            originalItem: i.originalItem || i,
                            previousItem: i,
                          }))
                        );
                      }
                      dispatch(reset('orderItemUpdate'));
                      dispatch(reset('trackingIdForm'));
                    }}
                  >
                    取り消す
                  </Button>
                  <Button
                    loading={updateOrderLoading || updateOrderLoading}
                    type="primary"
                    onClick={handleSubmitDirtyOrderDetail}
                  >
                    保存する
                  </Button>
                </Space>
              </Space>
            </div>
          </Affix>
        )}
      </div>
    </>
  );
};

export default withRefresh('orderDetail')(OrderDetail);
