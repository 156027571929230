import moment from 'moment';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  List,
  Avatar,
  Typography,
  Space,
} from 'antd';

import { ORDER_STATUS, ERROR_MESSAGE } from 'utils/constants';
import {
  createOrderMessageRequest,
  getOrderMessageListRequest,
} from 'providers/OrderProvider/actions';

import DefaultRestaurantImage from 'images/defaultRestaurant.svg';
import DefaultSupplierImage from 'images/defaultSupplier.svg';

const OrderMessage = ({ orderStatus, orderId }) => {
  const dataLoading = useSelector(
    (state) => state.orderProvider.orderMessages.dataLoading,
    shallowEqual
  );
  const actionLoading = useSelector(
    (state) => state.orderProvider.orderMessages.actionLoading,
    shallowEqual
  );
  const messages = useSelector(
    (state) => state.orderProvider.orderMessages.data,
    shallowEqual
  );
  const hash = useSelector((state) => state.router.location.hash, shallowEqual);

  useEffect(() => {
    if (hash === '#bottom-scr') {
      const element = document.getElementById('bottom-scr');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [hash, messages]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderMessageListRequest({ orderId }));
  }, [orderId]);

  const [form] = Form.useForm();
  const onReset = () => {
    form.resetFields();
  };
  const onSubmit = (values) => {
    dispatch(
      createOrderMessageRequest({
        orderId,
        content: values.content,
      })
    ).then(() => {
      onReset();
    });
  };

  if (
    ![
      ORDER_STATUS.SUBMITTED,
      ORDER_STATUS.PROCESSING,
      ORDER_STATUS.DELIVERING,
    ].includes(orderStatus)
    && messages.length === 0
  ) {
    return null;
  }

  return (
    <Card
      className="highlight-card"
      title="メッセージ"
      id="order-detail-message"
      loading={dataLoading}
      bordered={false}
    >
      <div className="message-list">
        {messages.length > 0 && (
          <List
            dataSource={messages}
            renderItem={(item) => {
              const supplierImage = _get(item, 'supplier.image');

              return (
                <List.Item key={item.objectId}>
                  <List.Item.Meta
                    avatar={(
                      <Avatar
                        src={
                          item.restaurant ? (
                            item.restaurant.image || (
                              <img
                                alt="restaurant-avatar"
                                src={DefaultRestaurantImage}
                              />
                            )
                          ) : (
                            <img
                              alt="operator-avatar"
                              src={supplierImage || DefaultSupplierImage}
                            />
                          )
                        }
                      />
                    )}
                    title={(
                      <Space size="middle">
                        <span>
                          {' '}
                          {_get(item, 'restaurant.name')
                            || _get(item, 'supplier.name')}{' '}
                        </span>
                        <Typography.Text
                          type="secondary"
                          style={{ fontSize: 12 }}
                        >
                          {moment(new Date(item.createdAt)).format(
                            'YYYY年MM月DD日 HH:mm'
                          )}
                        </Typography.Text>
                      </Space>
                    )}
                    description={(
                      <div
                        style={{
                          whiteSpace: 'pre-line',
                          fontSize: 14,
                          color: 'rgba(0, 0, 0, 0.65)',
                        }}
                      >
                        {item.content}
                      </div>
                    )}
                  />
                </List.Item>
              );
            }}
          />
        )}
        <div id="bottom-scr" />
      </div>
      {[
        ORDER_STATUS.SUBMITTED,
        ORDER_STATUS.PROCESSING,
        ORDER_STATUS.DELIVERING,
      ].includes(orderStatus) && (
        <>
          {messages.length > 0 && (
            <Divider
              style={{
                margin: '0 0 16px -16px',
                width: 'calc(100% + 32px)',
              }}
            />
          )}
          <Form form={form} onFinish={onSubmit}>
            <Form.Item
              name="content"
              rules={[
                { whitespace: true, message: ERROR_MESSAGE.REQUIRED },
                { required: true, message: ERROR_MESSAGE.REQUIRED },
              ]}
            >
              <Input.TextArea
                placeholder="メッセージを入力してください"
                style={{
                  resize: 'none',
                }}
              />
            </Form.Item>
            <Button
              loading={actionLoading}
              htmlType="submit"
              type="primary"
              className="submit-button"
            >
              送信
            </Button>
          </Form>
        </>
      )}
    </Card>
  );
};

OrderMessage.propTypes = {
  orderId: PropTypes.string,
  orderStatus: PropTypes.string,
};

export default OrderMessage;
