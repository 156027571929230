import _isString from 'lodash/isString';
import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import './style.scss';

class TableComponent extends React.PureComponent {
  onRowClick = (evt, record, rowIndex) => {
    const { onRowClick } = this.props;
    evt.preventDefault();
    let { target } = evt;
    if (!target) {
      return onRowClick(record, rowIndex);
    }
    if (target.tagName === 'path') {
      target = target.nearestViewportElement; // svg
    }
    const isCopyButton = target.getAttribute('data-icon') === 'copy'
      || (target.className
        && (target.className.includes('ant-typography-copy')
          || target.className.includes('anticon-copy')));
    if (!isCopyButton) {
      return onRowClick(record, rowIndex);
    }
    return () => {};
  };

  onChange = (pagination, filters, sorter) => {
    const { onChange } = this.props;

    let columnKey;
    let order;

    if (_isString(sorter?.columnKey) && _isString(sorter?.order)) {
      ({ columnKey, order } = sorter);
    }

    return onChange({
      pagination,
      filters,
      sorter: {
        orderBy: columnKey,
        order,
      },
    });
  };

  render() {
    const {
      rowSelection,
      columns,
      data,
      total,
      loading,
      pagination,
      onRowClick,
      bordered = false,
      expandedRowRender,
      expandRowByClick,
      onExpand,
      footer,
      scroll,
      rowKey = 'objectId',
      showHeader = true,
    } = this.props;

    const getMaxCurrent = () => {
      const { current, pageSize } = pagination;
      if (total <= pageSize && total > 0 && current > 1) {
        this.onChange({
          current: 1,
          pageSize,
        });
        return 1;
      }
      if (total > pageSize) {
        const maxPage = Math.ceil(total / pageSize);
        if (current > maxPage) {
          this.onChange({
            current: maxPage,
            pageSize,
          });
          return maxPage;
        }
      }
      return current;
    };

    return (
      <div>
        <Table
          className={pagination?.marginRight && 'pagination-margin-right'}
          onRow={(record, rowIndex) => ({
            onClick: (event) => onRowClick && this.onRowClick(event, record, rowIndex),
          })}
          rowClassName={() => onRowClick && 'clickable-row'}
          expandedRowRender={expandedRowRender}
          expandRowByClick={expandRowByClick}
          onExpand={onExpand}
          bordered={bordered}
          rowSelection={rowSelection}
          rowKey={rowKey}
          columns={columns}
          dataSource={data}
          loading={loading}
          footer={footer}
          scroll={scroll}
          pagination={
            pagination
              ? {
                pageSize: pagination.pageSize,
                current: getMaxCurrent(),
                total,
                showTotal: (totalItems, range) => `${range[0]} - ${range[1]} / ${totalItems}`,
              }
              : false
          }
          onChange={this.onChange}
          showHeader={showHeader}
        />
      </div>
    );
  }
}

TableComponent.propTypes = {
  onRowClick: PropTypes.func,
  data: PropTypes.array,
  total: PropTypes.number,
  loading: PropTypes.bool,
  rowSelection: PropTypes.object,
  columns: PropTypes.array,
  onChange: PropTypes.func,
  pagination: PropTypes.object,
  bordered: PropTypes.bool,
  expandedRowRender: PropTypes.func,
  expandRowByClick: PropTypes.bool,
  onExpand: PropTypes.any,
  footer: PropTypes.any,
  scroll: PropTypes.any,
  rowKey: PropTypes.any,
  showHeader: PropTypes.bool,
};

export default TableComponent;
