import produce from 'immer';

import {
  GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_REQUEST,
  GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_SUCCESS,
  GET_RESTAURANT_DISCOUNT_POINT_HISTORY_REQUEST,
  GET_RESTAURANT_DISCOUNT_POINT_HISTORY_SUCCESS,
} from './constants';

const initialState = {
  restaurantDiscountPointHistoryForUser: {},
  restaurantDiscountPointHistory: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_REQUEST:
      state.restaurantDiscountPointHistoryForUser = {};
      break;

    case GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_SUCCESS:
      state.restaurantDiscountPointHistoryForUser = action.payload;
      break;

    case GET_RESTAURANT_DISCOUNT_POINT_HISTORY_REQUEST:
      state.restaurantDiscountPointHistory = {};
      break;

    case GET_RESTAURANT_DISCOUNT_POINT_HISTORY_SUCCESS:
      state.restaurantDiscountPointHistory = action.payload;
      break;

    default:
  }
  return state;
});

export default reducer;
