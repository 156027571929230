import {
  GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_REQUEST,
  GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_SUCCESS,
  GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_ERROR,
  GET_RESTAURANT_DISCOUNT_POINT_HISTORY_REQUEST,
  GET_RESTAURANT_DISCOUNT_POINT_HISTORY_SUCCESS,
  GET_RESTAURANT_DISCOUNT_POINT_HISTORY_ERROR,
  GET_RESTAURANT_POINT_INFO_REQUEST,
  GET_RESTAURANT_POINT_INFO_SUCCESS,
  GET_RESTAURANT_POINT_INFO_ERROR,
  EXPORT_POINT_HISTORY_CSV_REQUEST,
  EXPORT_POINT_HISTORY_CSV_SUCCESS,
  EXPORT_POINT_HISTORY_CSV_ERROR,
} from './constants';

export function getRestaurantDiscountPointHistoryForUserRequest(payload) {
  return {
    type: GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getRestaurantDiscountPointHistoryForUserSuccess(payload, meta) {
  return {
    type: GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_SUCCESS,
    payload,
    meta,
  };
}

export function getRestaurantDiscountPointHistoryForUserError(payload, meta) {
  return {
    type: GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getRestaurantDiscountPointHistoryRequest(payload) {
  return {
    type: GET_RESTAURANT_DISCOUNT_POINT_HISTORY_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getRestaurantDiscountPointHistorySuccess(payload, meta) {
  return { type: GET_RESTAURANT_DISCOUNT_POINT_HISTORY_SUCCESS, payload, meta };
}

export function getRestaurantDiscountPointHistoryError(payload, meta) {
  return {
    type: GET_RESTAURANT_DISCOUNT_POINT_HISTORY_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getRestaurantPointInfoRequest(payload) {
  return {
    type: GET_RESTAURANT_POINT_INFO_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getRestaurantPointInfoSuccess(payload, meta) {
  return { type: GET_RESTAURANT_POINT_INFO_SUCCESS, payload, meta };
}

export function getRestaurantPointInfoError(payload, meta) {
  return {
    type: GET_RESTAURANT_POINT_INFO_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function exportPointHistoryCSVRequest(payload) {
  return {
    type: EXPORT_POINT_HISTORY_CSV_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function exportPointHistoryCSVSuccess(meta) {
  return { type: EXPORT_POINT_HISTORY_CSV_SUCCESS, meta };
}

export function exportPointHistoryCSVError(meta) {
  return { type: EXPORT_POINT_HISTORY_CSV_ERROR, meta, error: true };
}
