import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/ja';

import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import {
  Card,
  List,
  Tooltip,
  Button,
  Spin,
  Avatar,
  Radio,
  Typography,
  Space,
} from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

import toastMessage from 'utils/toastMessage';
import { DATE_TIME_FORMAT } from 'utils/constants';
import DefaultRestaurantImage from 'images/defaultRestaurant.svg';
import invitationIcon from 'images/invitation-notification.svg';
import orderIcon from 'images/order-notification.svg';
import airLogiIcon from 'images/airLogi-notification.svg';
import acceptIcon from 'images/accept-notification.svg';
import declineIcon from 'images/decline-notification.svg';
import './style.scss';

const AIR_LOGI_TYPES = [
  'REQUEST_CREATE_OR_UPDATE_FAILED',
  'REQUEST_CREATE_FAILED',
  'CREATE_CALLBACK_RESULT_FAILED',
  'GETTING_DATA_FAILED',
  'REQUEST_UPDATE_FAILED',
  'UPDATE_CALLBACK_RESULT_FAILED',
  'CANCEL_FAILED',
];
const NORMAL_TYPES = [
  'RECEIVED_NEW_ORDER',
  'ORDER_CANCELLED',
  'ORDER_CARD_UPDATED',
  'RECEIVED_NEW_INVITATION',
  'MENU_ASSIGNED',
  'INVOICE_SENT',
  'WEB_VIEW_LINK',
  'APPROVED_INVITATION',
  'REJECT_INVITATION',
  'RESTAURANT_OWNER_SEND_INVITATION',
  'RESTAURANT_ACCEPT_RESTAURANT_OWNER_INVITATION',
  'RESTAURANT_DECLINE_RESTAURANT_OWNER_INVITATION',
];

const renderNotificationItemExtraIconByType = (type) => {
  const iconMapByType = [
    {
      types: ['RESTAURANT_ACCEPT_RESTAURANT_OWNER_INVITATION'],
      icon: (
        <img
          src={acceptIcon}
          alt="invitation-icon"
          className="notification-badge"
        />
      ),
    },
    {
      types: ['RESTAURANT_DECLINE_RESTAURANT_OWNER_INVITATION'],
      icon: (
        <img
          src={declineIcon}
          alt="invitation-icon"
          className="notification-badge"
        />
      ),
    },
    {
      types: ['RECEIVED_NEW_INVITATION'],
      icon: (
        <img
          src={invitationIcon}
          alt="invitation-icon"
          className="notification-badge"
        />
      ),
    },
    {
      types: ['RECEIVED_NEW_ORDER', 'ORDER_CANCELLED', 'ORDER_CARD_UPDATED'],
      icon: (
        <img src={orderIcon} alt="order-icon" className="notification-badge" />
      ),
    },
    {
      types: AIR_LOGI_TYPES,
      icon: (
        <img
          src={airLogiIcon}
          alt="order-icon"
          className="notification-badge"
        />
      ),
    },
  ];

  return iconMapByType.find((iconMap) => iconMap.types.includes(type))?.icon;
};

class Notification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      page: 1,
      limit: 20,
      notificationType: 'SHIKOMEL',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      page, limit, visible, notificationType
    } = prevState;
    const { popupVisible, getNotificationListRequest } = nextProps;

    if (visible !== popupVisible) {
      if (popupVisible) {
        getNotificationListRequest({
          page,
          limit,
          types:
            notificationType === 'AIR_LOGI' ? AIR_LOGI_TYPES : NORMAL_TYPES,
        });
        return {
          visible: popupVisible,
        };
      }
      return {
        visible: popupVisible,
        page: 1,
        limit: 20,
      };
    }

    return null;
  }

  getTypes = (value) => (value === 'AIR_LOGI' ? AIR_LOGI_TYPES : NORMAL_TYPES);

  handleLoadMore = () => {
    this.setState(
      (prevState) => ({
        page: prevState.page + 1,
      }),
      () => {
        const { getNotificationListRequest } = this.props;
        const { page, limit, notificationType } = this.state;

        getNotificationListRequest({
          page,
          limit,
          types: this.getTypes(notificationType),
        });
      }
    );
  };

  handleDeleteNotification = (notificationId, index) => {
    const {
      deleteNotificationRequest,
      notificationListHasMore,
      notificationList,
    } = this.props;
    const { limit } = this.state;

    if (notificationList?.length < limit && notificationListHasMore) {
      this.handleLoadMore();
    }

    deleteNotificationRequest({ notificationId, index });
  };

  handleNotificationClick = (
    evt,
    path,
    notificationId,
    notificationType,
    index
  ) => {
    evt.preventDefault();
    const {
      push, closePopup, markNotificationReadRequest, doRefresh
    } = this.props;

    markNotificationReadRequest({ notificationId, index });

    if (!path) {
      if (
        notificationType === 'RESTAURANT_DECLINE_RESTAURANT_OWNER_INVITATION'
      ) {
        toastMessage.error({
          description: 'Restaurant declined your invitation',
        });
      }
      return;
    }

    if (path) {
      doRefresh({ target: 'orderDetail' });
      push(`${path}`);
      closePopup();
    }
  };

  handleNotificationTypeChange = (e) => {
    const { value } = e.target;
    const { getNotificationListRequest } = this.props;

    this.setState(
      {
        notificationType: value,
        page: 1,
      },
      () => {
        getNotificationListRequest({
          page: 1,
          limit: 20,
          types: this.getTypes(value),
        });
      }
    );
  };

  render() {
    const {
      notificationList,
      notificationLoading,
      notificationListHasMore,
      readAllNotificationRequest,
    } = this.props;

    const notificationExtra = (
      <Button
        className="extra-button"
        type="link"
        onClick={readAllNotificationRequest}
        icon={<CheckOutlined />}
      >
        すべて既読にする
      </Button>
    );

    const options = [
      { label: 'Shikomel', value: 'SHIKOMEL' },
      { label: 'Air Logi', value: 'AIR_LOGI' },
    ];

    const timeAgoJp = (time) => moment(time).locale('ja').fromNow();
    const { notificationType } = this.state;
    const { currentUser } = this.props;
    const renderHeader = (
      <div className="notification-header">
        <Space className="full-w flex justify-between">
          <Typography.Title level={4}>お知らせ</Typography.Title>
          {notificationExtra}
        </Space>
        {currentUser?.supplier?.type === 'SHIKOMEL' && (
          <Radio.Group
            options={options}
            optionType="button"
            defaultValue="SHIKOMEL"
            buttonStyle="solid"
            size="middle"
            onChange={this.handleNotificationTypeChange}
            value={notificationType}
          />
        )}
      </div>
    );
    return (
      <Card
        className="notification-container"
        title={renderHeader}
        headStyle={{
          fontSize: 20,
          fontWeight: 500,
        }}
      >
        <InfiniteScroll
          initialLoad={false}
          pageStart={1}
          loadMore={
            !notificationLoading
            && notificationListHasMore
            && this.handleLoadMore
          }
          hasMore={notificationListHasMore}
          useWindow={false}
        >
          <List
            dataSource={notificationList}
            renderItem={(item, index) => (
              <List.Item
                onClick={(evt) => this.handleNotificationClick(
                  evt,
                  item.linkTo,
                  item.objectId,
                  item?.type,
                  index
                )}
                key={item.objectId}
                className={`notification-item ${item.isRead ? '' : 'unread'}`}
                extra={(
                  <div className="notification-action">
                    <CloseOutlined
                      onClick={(evt) => {
                        evt.stopPropagation();
                        this.handleDeleteNotification(item.objectId, index);
                      }}
                    />
                  </div>
                )}
              >
                <List.Item.Meta
                  avatar={(
                    <div
                      style={{
                        position: 'relative',
                      }}
                    >
                      <Avatar
                        src={
                          item.image || (
                            <img
                              alt="restaurant-default"
                              src={DefaultRestaurantImage}
                            />
                          )
                        }
                        size={40}
                      />
                      {renderNotificationItemExtraIconByType(item?.type)}
                    </div>
                  )}
                  title={
                    <div className="notification-message">{item.message}</div>
                  }
                  description={(
                    <Tooltip
                      title={moment(item.createdAt).format(DATE_TIME_FORMAT)}
                      placement="rightTop"
                    >
                      <span className="notification-time">
                        {timeAgoJp(item.createdAt)}
                      </span>
                    </Tooltip>
                  )}
                />
                {item.content}
              </List.Item>
            )}
          >
            {notificationLoading && notificationListHasMore && (
              <div className="notification-loading">
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll>
      </Card>
    );
  }
}

Notification.propTypes = {
  getNotificationListRequest: PropTypes.func,
  readAllNotificationRequest: PropTypes.func,
  notificationList: PropTypes.array,
  notificationListHasMore: PropTypes.bool,
  notificationLoading: PropTypes.bool,
  deleteNotificationRequest: PropTypes.func,
  push: PropTypes.func,
  closePopup: PropTypes.func,
  markNotificationReadRequest: PropTypes.func,
  doRefresh: PropTypes.func,
  popupVisible: PropTypes.any,
  currentUser: PropTypes.object,
};

export default Notification;
