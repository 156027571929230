import moment from 'moment';
import _get from 'lodash/get';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  Card,
  Typography,
  Space,
  Input,
  Tag,
  DatePicker,
  Select,
  Button,
} from 'antd';
import { Link } from 'react-router-dom';
import {
  ArrowRightOutlined,
  CloseOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import {
  exportPointHistoryCSVRequest,
  getRestaurantDiscountPointHistoryRequest,
} from 'providers/RestaurantPointProvider/actions';

import helpers from 'utils/helpers';
import { DATE_TIME_FORMAT } from 'utils/constants';

import CalendarIcon from 'images/icon-calendar.svg';

import useTableControl from 'components/Hooks/useTableControl';
import Table from 'components/Table';

const { Text } = Typography;
const { Search } = Input;
const { RangePicker } = DatePicker;

const TransactionType = {
  GrantedInvoice: 'GrantedInvoice',
  GrantedReturnedOrder: 'GrantedReturnedOrder',
  GrantedCancelledOrder: 'GrantedCancelledOrder',
  UsageOrder: 'UsageOrder',
  ExpireExpiredPoint: 'ExpireExpiredPoint',
  ExpireDeleteAccount: 'ExpireDeleteAccount',
};
const TransactionTypeColor = {
  [TransactionType.GrantedInvoice]: '#399E0E',
  [TransactionType.GrantedReturnedOrder]: '#399E0E',
  [TransactionType.GrantedCancelledOrder]: '#399E0E',
  [TransactionType.UsageOrder]: '#CF1421',
  [TransactionType.ExpireExpiredPoint]: '#000000D9',
  [TransactionType.ExpireDeleteAccount]: '#000000D9',
};
const TransactionTypeJP = {
  [TransactionType.GrantedInvoice]: '獲得',
  [TransactionType.GrantedReturnedOrder]: '獲得',
  [TransactionType.GrantedCancelledOrder]: '獲得',
  [TransactionType.UsageOrder]: '利用',
  [TransactionType.ExpireExpiredPoint]: '失効',
  [TransactionType.ExpireDeleteAccount]: '失効',
};
const TransactionPointColor = {
  [TransactionType.GrantedInvoice]: '##000000D9',
  [TransactionType.GrantedReturnedOrder]: '##000000D9',
  [TransactionType.GrantedCancelledOrder]: '##000000D9',
  [TransactionType.UsageOrder]: '#CF1421',
  [TransactionType.ExpireExpiredPoint]: '#CF1421',
  [TransactionType.ExpireDeleteAccount]: '#CF1421',
};

const PointHistory = () => {
  const restaurantDiscountPointHistory = useSelector(
    (state) => state?.restaurantPointProvider?.restaurantDiscountPointHistory,
    shallowEqual
  );

  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({
    filterKeys: {
      restaurantId: 'ri',
      createdFrom: 'cf',
      createdTo: 'ct',
      type: 't',
    },
  });
  const {
    page, limit, orderBy, order
  } = pageControl;
  const {
    restaurantId, createdFrom, createdTo, type
  } = filterControl;

  const [restaurantIdFilter, setRestaurantIdFilter] = useState(restaurantId);
  const [createdFilter, setCreatedFilter] = useState([
    createdFrom && moment(createdFrom),
    createdTo && moment(createdTo),
  ]);
  const [typeFilter, setTypeFilter] = useState(type);

  const dispatch = useDispatch();

  const [tableLoading, setTableLoading] = useState(false);

  const fetchData = async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getRestaurantDiscountPointHistoryRequest({
          page,
          limit,
          orderBy,
          order,
          restaurantId,
          createdFrom,
          createdTo,
          type,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, limit, orderBy, order, restaurantId, createdFrom, createdTo, type]);

  const [exportCSVLoading, setExportCSVLoading] = useState(false);
  const handleExportCSV = async () => {
    setExportCSVLoading(true);
    try {
      await dispatch(
        exportPointHistoryCSVRequest({
          restaurantId,
          createdFrom,
          createdTo,
          type,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setExportCSVLoading(false);
  };

  const columns = [
    {
      title: '履歴日',
      dataIndex: 'createdAt',
      render: (record) => (record ? moment(record).format(DATE_TIME_FORMAT) : '-'),
    },
    {
      title: '種別',
      dataIndex: 'type',
      render: (record) => (
        <Tag color={TransactionTypeColor[record]}>
          {TransactionTypeJP[record]}
        </Tag>
      ),
    },
    {
      title: '注文',
      dataIndex: 'orderId',
      render: (record) => <Link to={`/orders/detail/${record}`}>{record}</Link>,
    },
    {
      title: 'レストランID',
      render: (record) => record?.restaurant?.formattedObjectId,
    },
    {
      title: 'レストラン名',
      render: (record) => (
        <Link
          to={`/restaurants/detail/${record?.restaurant?.formattedObjectId}/in-app`}
        >
          {record?.restaurant?.name}
        </Link>
      ),
    },
    {
      title: 'ポイント',
      align: 'right',
      render: (record) => (
        <Text
          style={{
            color: TransactionPointColor[record?.type],
          }}
        >
          {[
            TransactionType.UsageOrder,
            TransactionType.ExpireExpiredPoint,
            TransactionType.ExpireDeleteAccount,
          ].includes(record?.type) && '-'}
          {helpers.numberWithCommas(record?.point || 0)}pt
        </Text>
      ),
    },
  ];

  return (
    <Card className="bg-transparent" bordered={false}>
      <Card className="highlight-card padding-card" bordered={false}>
        <div
          className="flex justify-between items-center"
          style={{ padding: 16 }}
        >
          <Space size={8}>
            <Search
              placeholder="レストランID"
              value={restaurantIdFilter}
              onChange={(e) => setRestaurantIdFilter(e?.target?.value)}
              onSearch={(value) => {
                onFilterChange({ restaurantId: value });
              }}
              style={{ width: 200 }}
            />
            <RangePicker
              placeholder={['開始日', '終了日']}
              onBlur={(e) => e.preventDefault()}
              format="YYYY/MM/DD"
              suffixIcon={<img alt="calendar-icon" src={CalendarIcon} />}
              separator={<ArrowRightOutlined />}
              value={createdFilter}
              onChange={(date, dateString) => {
                setCreatedFilter(date);
                onFilterChange({
                  createdFrom: dateString[0]
                    ? moment(dateString[0]).startOf('day').toISOString()
                    : '',
                  createdTo: dateString[1]
                    ? moment(dateString[1]).endOf('day').toISOString()
                    : '',
                });
              }}
            />
            <Select
              allowClear
              labelInValue
              placeholder="履歴の種別"
              value={typeFilter}
              onChange={(option) => {
                setTypeFilter(option?.value);
                onFilterChange({ type: option?.value });
              }}
              style={{ width: 200 }}
            >
              <Select.Option key="Granted" value="Granted">
                獲得
              </Select.Option>
              <Select.Option key="Usage" value="Usage">
                利用
              </Select.Option>
              <Select.Option key="Expire" value="Expire">
                失効
              </Select.Option>
            </Select>
            <Button
              type="link"
              icon={<CloseOutlined />}
              style={{ padding: 0 }}
              hidden={!restaurantId && !createdFrom && !createdTo && !type}
              onClick={() => {
                setRestaurantIdFilter('');
                setCreatedFilter([null, null]);
                setTypeFilter(null);
                onFilterChange({
                  restaurantId: '',
                  createdFrom: '',
                  createdTo: '',
                  type: '',
                });
              }}
            >
              Clear all filters
            </Button>
          </Space>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            loading={exportCSVLoading}
            onClick={handleExportCSV}
          >
            CSVダウンロード
          </Button>
        </div>
        <Table
          columns={columns}
          data={_get(restaurantDiscountPointHistory, 'list', [])}
          total={_get(restaurantDiscountPointHistory, 'total', 0)}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
        />
      </Card>
    </Card>
  );
};

export default PointHistory;
