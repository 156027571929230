export const GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_REQUEST = 'RestaurantPointProvider/GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_REQUEST';
export const GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_SUCCESS = 'RestaurantPointProvider/GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_SUCCESS';
export const GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_ERROR = 'RestaurantPointProvider/GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_ERROR';

export const GET_RESTAURANT_DISCOUNT_POINT_HISTORY_REQUEST = 'RestaurantPointProvider/GET_RESTAURANT_DISCOUNT_POINT_HISTORY_REQUEST';
export const GET_RESTAURANT_DISCOUNT_POINT_HISTORY_SUCCESS = 'RestaurantPointProvider/GET_RESTAURANT_DISCOUNT_POINT_HISTORY_SUCCESS';
export const GET_RESTAURANT_DISCOUNT_POINT_HISTORY_ERROR = 'RestaurantPointProvider/GET_RESTAURANT_DISCOUNT_POINT_HISTORY_ERROR';

export const GET_RESTAURANT_POINT_INFO_REQUEST = 'RestaurantPointProvider/GET_RESTAURANT_POINT_INFO_REQUEST';
export const GET_RESTAURANT_POINT_INFO_SUCCESS = 'RestaurantPointProvider/GET_RESTAURANT_POINT_INFO_SUCCESS';
export const GET_RESTAURANT_POINT_INFO_ERROR = 'RestaurantPointProvider/GET_RESTAURANT_POINT_INFO_ERROR';

export const EXPORT_POINT_HISTORY_CSV_REQUEST = 'RestaurantProvider/EXPORT_POINT_HISTORY_CSV_REQUEST';
export const EXPORT_POINT_HISTORY_CSV_SUCCESS = 'RestaurantProvider/EXPORT_POINT_HISTORY_CSV_SUCCESS';
export const EXPORT_POINT_HISTORY_CSV_ERROR = 'RestaurantProvider/EXPORT_POINT_HISTORY_CSV_ERROR';
