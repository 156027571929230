import moment from 'moment';
import _get from 'lodash/get';
import { call, put, takeLeading } from 'redux-saga/effects';
import request from 'utils/request';
import {
  CLOUD_FUNCTION_PREFIX,
  MESSAGE,
  UTF_8_BOM,
  DATE_FORMAT_CSV_FILE_NAME,
} from 'utils/constants';
import Helpers from 'utils/helpers';
import toastMessage from 'utils/toastMessage';
import {
  getRestaurantDiscountPointHistoryForUserSuccess,
  getRestaurantDiscountPointHistoryForUserError,
  getRestaurantDiscountPointHistorySuccess,
  getRestaurantDiscountPointHistoryError,
  getRestaurantPointInfoSuccess,
  getRestaurantPointInfoError,
  exportPointHistoryCSVSuccess,
  exportPointHistoryCSVError,
} from './actions';
import {
  GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_REQUEST,
  GET_RESTAURANT_DISCOUNT_POINT_HISTORY_REQUEST,
  GET_RESTAURANT_POINT_INFO_REQUEST,
  EXPORT_POINT_HISTORY_CSV_REQUEST,
} from './constants';

export function* handleGetRestaurantDiscountPointHistoryForUser(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRestaurantDiscountPointHistoryForUser`,
      payload
    );
    yield put(getRestaurantDiscountPointHistoryForUserSuccess(result, meta));
  } catch (error) {
    yield put(getRestaurantDiscountPointHistoryForUserError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetRestaurantDiscountPointHistory(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRestaurantDiscountPointHistory`,
      payload
    );
    yield put(getRestaurantDiscountPointHistorySuccess(result, meta));
  } catch (error) {
    yield put(getRestaurantDiscountPointHistoryError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetRestaurantPointInfo(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRestaurantPointInfo`,
      payload
    );
    yield put(getRestaurantPointInfoSuccess(result, meta));
  } catch (error) {
    yield put(getRestaurantPointInfoError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleExportPointHistoryCSV(action) {
  try {
    const { data } = yield call(
      request,
      '/point-history/export',
      action.payload
    );
    const fileName = `${
      process.env.REACT_APP_CSV_PREFIX || ''
    }${moment().format(DATE_FORMAT_CSV_FILE_NAME)}_ポイント付与・利用履歴.csv`;

    Helpers.saveAsFile(data, fileName, 'text/csv;charset=utf-8', UTF_8_BOM);
    yield put(exportPointHistoryCSVSuccess(action.meta));
    toastMessage.success({ description: MESSAGE.EXPORT_CSV_SUCCESS });
  } catch (error) {
    yield put(exportPointHistoryCSVError(action.meta));
    toastMessage.error({
      description: _get(error.error, 'message', error.error),
    });
  }
}

export default function* saga() {
  yield takeLeading(
    GET_RESTAURANT_DISCOUNT_POINT_HISTORY_FOR_USER_REQUEST,
    handleGetRestaurantDiscountPointHistoryForUser
  );
  yield takeLeading(
    GET_RESTAURANT_DISCOUNT_POINT_HISTORY_REQUEST,
    handleGetRestaurantDiscountPointHistory
  );
  yield takeLeading(
    GET_RESTAURANT_POINT_INFO_REQUEST,
    handleGetRestaurantPointInfo
  );
  yield takeLeading(
    EXPORT_POINT_HISTORY_CSV_REQUEST,
    handleExportPointHistoryCSV
  );
}
