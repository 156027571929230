import _get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Descriptions,
  Typography,
  Collapse,
  Alert,
  Space,
  Tag,
  Avatar,
  Tooltip,
} from 'antd';

import {
  DATE_FORMAT,
  ORDER_STATUS_COLOR,
  ORDER_STATUS_JP,
  AIR_LOGI_ORDER_SYNC_STATUS_ERROR,
} from 'utils/constants';
import helpers from 'utils/helpers';

import CardInfo from 'components/CardInfo';
import DefaultRestaurantImage from 'images/defaultRestaurant.svg';
import NoteIcon from 'images/icon-note.svg';
import ArrowDownIcon from 'images/icon-arrow-down.svg';
import CopyIcon from 'images/icon-copy.svg';

import './style.scss';

const { Panel } = Collapse;
const { Text } = Typography;

const DELIVERY_TIME_RANGE = {
  AM: '午前（9時〜12時）',
  PM: '午後（13時〜17時）',
  MORNING: '午前中',
  1214: '12時-14時',
  1416: '14時-16時',
  1618: '16時-18時',
  1820: '18時-20時',
  1921: '19時-21時',
};

const renderAddressDescription = (
  _postalCode,
  _prefecture,
  _address,
  _buildingName,
  _phone,
  _fax
) => (
  <Descriptions.Item>
    <div>
      <div>{_postalCode}</div>
      <div>
        {_prefecture} {_address} {_buildingName}
      </div>
      <div>店舗電話番号: {_phone}</div>
      <div>店舗FAX番号: {_fax}</div>
    </div>
  </Descriptions.Item>
);

const DeliveryInfo = ({ orderDetail }) => {
  const orderStatus = _get(orderDetail, 'status');
  const cardNumber = _get(orderDetail, 'cardInfo.cardNumber');
  const paymentMethod = _get(orderDetail, 'paymentMethod');
  const cardNiceType = helpers.getCardType(cardNumber);
  const airLogiInfo = orderDetail?.airLogi_info;

  let status = (
    <Tag color={ORDER_STATUS_COLOR[orderStatus]}>
      {ORDER_STATUS_JP[orderStatus]}
    </Tag>
  );

  if (AIR_LOGI_ORDER_SYNC_STATUS_ERROR.includes(airLogiInfo?.syncStatus)) {
    status = (
      <Tag color={ORDER_STATUS_COLOR.PAYMENT_FAILED}>
        {ORDER_STATUS_JP.AIR_LOGI_ERROR}
      </Tag>
    );
  }

  const restaurantName = _get(
    orderDetail,
    'restaurantName',
    _get(orderDetail, 'restaurant.name', '—')
  );

  return (
    <Collapse
      className="highlight-card delivery-info"
      style={{ overflow: 'hidden' }}
      defaultActiveKey={['1']}
      expandIconPosition="right"
      bordered={false}
      expandIcon={({ isActive }) => (
        <img
          src={ArrowDownIcon}
          alt="expand-Icon"
          style={{ transform: isActive ? 'rotate(0deg)' : 'rotate(270deg)' }}
        />
      )}
    >
      <Panel
        style={{ backgroundColor: '#fff', borderBottom: 'none' }}
        header={(
          <Typography.Text
            style={{ fontSize: 20, fontWeight: 500, color: '#000' }}
          >
            注文情報
          </Typography.Text>
        )}
        key="1"
      >
        <Space direction="vertical" size="middle" className="full-w">
          {_get(orderDetail, 'note') && (
            <Alert
              icon={<img alt="note-icon" src={NoteIcon} />}
              showIcon
              message={(
                <div style={{ whiteSpace: 'pre-line' }}>
                  {_get(orderDetail, 'note')}
                </div>
              )}
              type="info"
            />
          )}
          <Descriptions
            layout="horizontal"
            column={1}
            bordered
            labelStyle={{ verticalAlign: 'top', color: '#00000073' }}
            contentStyle={{ verticalAlign: 'top' }}
          >
            <Descriptions.Item label="注文の状況">{status}</Descriptions.Item>
            <Descriptions.Item label="お支払い方法">
              <CardInfo method={paymentMethod} niceType={cardNiceType} />
            </Descriptions.Item>
            <Descriptions.Item label="店舗名">
              <div className="flex items-center full-w">
                <Avatar
                  src={_get(
                    orderDetail,
                    'restaurant.image',
                    DefaultRestaurantImage
                  )}
                  size={32}
                />
                <div className="restaurant-info">
                  <Tooltip title={restaurantName}>
                    <Text
                      style={{ width: 200 }}
                      ellipsis={{
                        tooltip: restaurantName,
                      }}
                    >
                      {restaurantName}
                    </Text>
                  </Tooltip>
                  <br />
                  <Text
                    copyable={
                      !!_get(orderDetail, 'restaurant.email') && {
                        tooltips: ['コピー', 'コピーしました'],
                        icon: <img src={CopyIcon} alt="copy-icon" />,
                      }
                    }
                    type="secondary"
                    style={{ fontSize: 12 }}
                  >
                    {_get(orderDetail, 'restaurant.email', '—')}
                  </Text>
                </div>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="納品希望日">
              {moment(_get(orderDetail, 'deliveryDate')).format(DATE_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item
              label={(
                <div>
                  配送に
                  <br />
                  関する情報
                </div>
              )}
            >
              {renderAddressDescription(
                _get(orderDetail, 'deliveryInfo.deliveryPostalCode'),
                _get(orderDetail, 'deliveryInfo.deliveryPrefecture'),
                _get(orderDetail, 'deliveryInfo.deliveryAddress'),
                _get(orderDetail, 'deliveryInfo.deliveryBuildingName'),
                _get(orderDetail, 'deliveryInfo.deliveryPhone'),
                _get(orderDetail, 'deliveryInfo.deliveryFax')
              )}
            </Descriptions.Item>
            <Descriptions.Item label="納品場所補足情報">
              <div style={{ whiteSpace: 'pre-line' }}>
                {_get(orderDetail, 'deliveryInfo.deliveryNote', '—')}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="定休日">
              {_get(orderDetail, 'restaurant.closingDays', '—')}
            </Descriptions.Item>
            <Descriptions.Item label="納品希望時間">
              {DELIVERY_TIME_RANGE[
                _get(orderDetail, 'deliveryInfo.deliveryTimeRange')
              ] || '指定なし'}
            </Descriptions.Item>
            <Descriptions.Item label="納品書の希望">
              {_get(orderDetail, 'deliveryStatement') === true
                ? '希望する'
                : '希望しない'}
            </Descriptions.Item>
          </Descriptions>
        </Space>
      </Panel>
    </Collapse>
  );
};

DeliveryInfo.propTypes = {
  orderDetail: PropTypes.object,
};

export default DeliveryInfo;
