module.exports = {
  MESSAGE: {
    EXPORT_CSV_SUCCESS: 'CSVを出力しました。',
    EXPORT_PDF_SUCCESS: 'PDFを出力しました。',
    CHANGE_PASSWORD_SUCCESS: 'Change password successfully',
    UPDATE_SUCCESS: '更新しました',
    RESET_PASSWORD_SUCCESS: 'Please check your email to reset password',
    DELETE_RESTAURANT_ANNOUNCEMENT_SUCCESS:
      'Delete restaurant announcement successfully',
  },
  ERROR_MESSAGE: {
    REQUIRED: '必須項目です',
    INVALID: '無効',
    INVALID_PASSWORD: '8文字以上の半角英数字・記号で入力してください',
    INVALID_PHONE: '有効な電話番号を入力してください。（ハイフンなし・半角）',
    INVALID_FAX: '有効なFAX番号を入力してください。',
  },
  PAYMENT_STATUS: {
    FAILED: 'FAILED',
    UNPAID: 'UNPAID',
  },
  CLOUD_FUNCTION_PREFIX: '/functions/web_',
  DATE_TIME_FORMAT: 'YYYY/MM/DD HH:mm',
  DATE_FORMAT: 'YYYY/MM/DD',
  DATE_FORMAT_CSV_FILE_NAME: 'YYMMDD',
  STATUS: {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    DELETED: 'DELETED',
  },
  ORDER_STATUS: {
    PAYMENT_FAILED: 'PAYMENT_FAILED',
    SUBMITTED: 'SUBMITTED',
    PROCESSING: 'PROCESSING',
    CANCELLED: 'CANCELLED',
    DELIVERING: 'DELIVERING',
    DELIVERED: 'DELIVERED',
    FIXED: 'FIXED',
  },
  ORDER_STATUS_JP: {
    PAYMENT_FAILED: '決済エラー',
    SUBMITTED: '注文未確定',
    PROCESSING: '注文受領',
    CANCELLED: '発注不可・取消',
    DELIVERING: '発送中',
    DELIVERED: '配送完了',
    FIXED: '確定',
    AIR_LOGI_ERROR: 'AiR Logi エラー',
  },
  ORDER_STATUS_COLOR: {
    SUBMITTED: '#edb315',
    PROCESSING: '#ee780f',
    CANCELLED: '#a6a6a6',
    DELIVERING: '#4aaee8',
    DELIVERED: 'green',
    FIXED: 'black',
    PAYMENT_FAILED: '#cf1421',
  },
  AIR_LOGI_ORDER_SYNC_STATUS_ERROR: [
    'REQUEST_CREATE_FAILED',
    'CREATE_CALLBACK_RESULT_FAILED',
    'GETTING_DATA_FAILED',
    'ORDER_NOT_FOUND',
    'CANCEL_FAILED',
  ],
  MENU_TAX_OPTIONS: [
    {
      objectId: 0,
      name: '非課税',
    },
    {
      objectId: 0.08,
      name: '8%',
    },
    {
      objectId: 0.1,
      name: '10%',
    },
  ],
  MENU_TYPE: {
    NORMAL_MENU: 'NORMAL_MENU',
    BASIC_MENU: 'BASIC_MENU',
  },
  MENU_TYPE_FILTER: {
    ALL: 0,
    NORMAL: -1,
    BASIC: 1,
  },
  MENU_TYPE_JP: {
    NORMAL_MENU: '店舗メニュー',
    BASIC_MENU: 'ベーシックメニュー',
  },
  MAX_LIMIT_PRICE: 999999999999,
  MAX_LIMIT_ORDER: 999,
  UTF_8_BOM: new Uint8Array([0xef, 0xbb, 0xbf]),
  SENDER: {
    OPERATOR: 'OPERATOR',
    RESTAURANT: 'RESTAURANT',
  },
  NOTIFICATION_TYPE: {
    RECEIVED_NEW_ORDER: 'RECEIVED_NEW_ORDER',
    ORDER_CANCELLED: 'ORDER_CANCELLED',
    ORDER_CARD_UPDATED: 'ORDER_CARD_UPDATED',
  },
  ANNOUNCEMENT_STATUS: {
    PENDING: 'PENDING',
    PUBLISHED: 'PUBLISHED',
    RUNNING: 'RUNNING',
  },
  IN_APP_MESSAGE_STATUS: {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    PENDING: 'PENDING',
  },
  USER_ROLE: {
    OPERATOR: 'OPERATOR',
    SUPPLIER: 'SUPPLIER',
    BRAND_OWNER: 'BRAND_OWNER',
    RESTAURANT_OWNER: 'RESTAURANT_OWNER',
  },
  CONNECT_STATUS: {
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    DELETED: 'DELETED',
  },
  MENU_LIST_FILTER: {
    WITH_BRAND: 'WITH_BRAND',
    WITHOUT_BRAND: 'WITHOUT_BRAND',
    BASIC: 'BASIC',
    NOT_BASIC: 'NOT_BASIC',
    BASIC_PLAN_PRODUCT: 'MEMBERSHIP_TIER_1',
    PREMIUM_PLAN_PRODUCT: 'MEMBERSHIP_TIER_2',
  },
  MENU_PRICE_TYPE: {
    MASTER: 'MASTER',
    OTHER: 'OTHER',
  },
  WORKING_DAYS: {
    1: '月曜日',
    2: '火曜日',
    3: '水曜日',
    4: '木曜日',
    5: '金曜日',
    6: '土曜日',
    7: '日曜日',
  },
  SHIPPING_METHOD: [
    { objectId: 'OWN_SHIPPING', name: '自社配送' },
    {
      objectId: 'SHIPPING_COMPANY',
      name: '配送業者',
    },
  ],
  GENERAL_ANSWER: [
    { objectId: true, name: 'はい' },
    { objectId: false, name: 'いいえ' },
  ],
  SHIPPING_METHOD_TYPE: {
    OWN_SHIPPING: 'OWN_SHIPPING',
    SHIPPING_COMPANY: 'SHIPPING_COMPANY',
  },
  REGEX: {
    PASSWORD: /(?=.{8,})/,
  },
  WAREHOUSE_MENU_SYNC_STATUS: [
    {
      keys: [
        'REQUEST_CREATE_OR_UPDATE_PENDING',
        'CREATE_PENDING',
        'WAITING_CREATE_CALLBACK',
        'WAITING_FOR_PRODUCT_ID',
        'GETTING_DATA',
        'REQUEST_UPDATE_PENDING',
        'WAITING_UPDATE_CALLBACK',
      ],
      label: '同期中',
      className: 'syncing',
    },
    {
      keys: [
        'REQUEST_CREATE_OR_UPDATE_FAILED',
        'REQUEST_CREATE_FAILED',
        'GETTING_DATA_FAILED',
        'CREATE_CALLBACK_RESULT_FAILED',
        'REQUEST_UPDATE_FAILED',
        'UPDATE_CALLBACK_RESULT_FAILED',
      ],
      label: '同期エラー',
      className: 'sync-error',
    },
    {
      keys: ['SYNCED'],
      label: '同期済',
      className: 'synced',
    },
  ],
  PRODUCT_PRICE_TYPE: {
    MASTER: 'MASTER',
    OTHER: 'OTHER',
    DISCOUNT: 'DISCOUNT',
  },
  MEMBERSHIP_STATUS: {
    IN_PREMIUM_TRIAL: 'IN_PREMIUM_TRIAL',
    CAN_TRIAL: 'CAN_TRIAL',
    IN_PREMIUM: 'IN_PREMIUM',
    IN_CANCELLING_PREMIUM: 'IN_CANCELLING_PREMIUM',
    IN_BASIC: 'IN_BASIC',
  },
  BANNER_TYPE: {
    SHIKOMEL_STORE: 'Shikomel Store',
    MY_STORE: 'My Store',
  },
  IMAGE_ACCEPT_TYPE: '.png, .jpeg, .jpg',
  DESTINATION_TYPE: {
    FILE: 'file',
    PRODUCT: 'product',
    URL: 'url',
  },
  EFFECTIVE_DATE: {
    NOW: 'now',
    SCHEDULE: 'schedule',
  },
  EXPIRED_DATE: {
    NEVER: 'never',
    SCHEDULE: 'schedule',
  },
  SHIKOMEL: 'SHIKOMEL',
  APPLY_TO: {
    WAREHOUSE: {
      value: 'WAREHOUSE',
      label: '倉庫',
    },
    MANUFACTURER: { value: 'MANUFACTURER', label: '製造会社' },
  },
  RESTAURANT_ANNOUNCEMENT: {
    TARGET: {
      ALL: 'ALL',
      RESTAURANT_GROUP: 'RESTAURANT_GROUP',
    },
    RESTAURANT_GROUP_KEY: {
      GUEST_USER: 'GUEST_USER',
      USER_HAS_ORDER: 'USER_HAS_ORDER',
      USER_HAS_NO_ORDER: 'USER_HAS_NO_ORDER',
      LOGGED_IN_USER: 'LOGGED_IN_USER',
      USER_REGISTER_DATE_VERSUS_TIME: 'USER_REGISTER_DATE_VERSUS_TIME',
      USER_LAST_ORDER_DATE_VERSUS_TIME: 'USER_LAST_ORDER_DATE_VERSUS_TIME',
      CUSTOM_USER: 'CUSTOM_USER',
    },
  },
};
