import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import {
  Descriptions, Space, Typography, Button, Skeleton
} from 'antd';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import helpers from 'utils/helpers';
import { DATE_FORMAT } from 'utils/constants';

import { getRestaurantPointInfoRequest } from 'providers/RestaurantPointProvider/actions';

const { Text } = Typography;
const { Item } = Descriptions;

moment.tz.setDefault('Asia/Tokyo');

const RESTAURANT_RANK_JP = {
  PART_TIME_JOB: 'アルバイト',
  LEADER: 'バイトリーダー',
  SHOP_MANAGER: '店長',
  AREA_MANAGER: 'エリアマネージャー',
  CEO: '社長',
};

const RestaurantPointInfo = ({ restaurantId, restaurantFormattedId }) => {
  const dispatch = useDispatch();

  const [isFetchingRestaurantPointInfo, setIsFetchingRestaurantPointInfo] = useState(true);
  const [restaurantPointInfo, setRestaurantPointInfo] = useState();
  const fetchPointInfo = useCallback(async () => {
    if (!restaurantId) {
      return;
    }
    setIsFetchingRestaurantPointInfo(true);
    try {
      const pointInfoResult = await dispatch(
        getRestaurantPointInfoRequest({
          restaurantId,
        })
      );
      setRestaurantPointInfo(pointInfoResult);
    } catch (error) {
      console.error(error);
    }
    setIsFetchingRestaurantPointInfo(false);
  }, [restaurantId, dispatch]);

  useEffect(() => {
    fetchPointInfo();
  }, [fetchPointInfo]);

  return (
    <>
      <Item label="会員ランク">
        {isFetchingRestaurantPointInfo ? (
          <Space direction="vertical" size="small">
            <Skeleton.Input size="small" active />
            <Skeleton.Input size="small" active />
          </Space>
        ) : (
          <Space direction="vertical" size={0}>
            <Text>
              {RESTAURANT_RANK_JP[restaurantPointInfo?.rankInfo?.rank] || '-'}
            </Text>
            <Text type="secondary">
              期間内対象商品購入金額:{' '}
              <span>
                {helpers.numberWithCommas(
                  restaurantPointInfo?.estimatedRankInfo?.rankPoint || 0
                )}
                円
              </span>
            </Text>
          </Space>
        )}
      </Item>
      <Item label="シコメルストア限定ポイント">
        <Space className="flex justify-between">
          {isFetchingRestaurantPointInfo ? (
            <Space direction="vertical" size="small">
              <Skeleton.Input size="small" active />
              <Skeleton.Input size="small" active />
            </Space>
          ) : (
            <Space direction="vertical" size={0}>
              <Text>
                {helpers.numberWithCommas(
                  restaurantPointInfo?.pointInfo?.discountPoint || 0
                )}
                pt
              </Text>
              <Text type="secondary">
                利用期限:{' '}
                {restaurantPointInfo?.pointInfo?.expiredAt
                && (restaurantPointInfo?.pointInfo?.discountPoint || 0) > 0
                  ? moment(restaurantPointInfo?.pointInfo?.expiredAt).format(
                    DATE_FORMAT
                  )
                  : '-'}
              </Text>
            </Space>
          )}
          <Button
            type="ghost"
            onClick={() => dispatch(
              push(
                `/restaurants/detail/${restaurantFormattedId}/point-history`
              )
            )}
          >
            履歴
          </Button>
        </Space>
      </Item>
    </>
  );
};

RestaurantPointInfo.propTypes = {
  restaurantId: PropTypes.string,
  restaurantFormattedId: PropTypes.string,
};

export default RestaurantPointInfo;
